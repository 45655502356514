import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import textService from '../../../services/TextService.js';

export default function MultipleChoiceQuestion(props) {

    const [value, setValue] = React.useState('');

    function handleChange(event) {
        setValue(event.target.value);
    }

  return (
    <React.Fragment>
        <Typography variant="h6" gutterBottom>
            { textService.formatBreakLines(props.content.description)}
        </Typography>
        {
            props.showOptions && <FormControl component="fieldset" >
                <RadioGroup aria-label="gender" name="gender2" value={value} onChange={handleChange}>
                    {
                        props.content.multipleChoiceOptions.map((item, key)=>{
                            return <FormControlLabel
                                value={item.id}
                                control={<Radio color="primary" />}
                                label={item.text}
                                labelPlacement="start"
                                key={key}
                                readOnly={true}
                                disabled
                              />
                        })

                    }
                </RadioGroup>
              </FormControl>
        }
    </React.Fragment>
  );
}
