import React, { useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import multiplechoicequestionicon from "../../../assets/images/multiplechoicequestionicon.png";
import textquestionicon from "../../../assets/images/textquestionicon.png";
import attachmentquestionicon from "../../../assets/images/imgquestionicon.png";
import obsquestionicon from "../../../assets/images/obsquestionicon.png";
import sectionquestionicon from "../../../assets/images/sectionquestionicon.png";
import subtitlequestionicon from "../../../assets/images/subtitlequestionicon.png";
import titlequestionicon from "../../../assets/images/titlequestionicon.png";

import TextQuestion from "./TextQuestion";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import AttachmentQuestion from "./AttachmentQuestion";
import Button from "@material-ui/core/Button";

/*
    Generador de íconos selectores para tipos de pregunta.
    Contienen identificar y visualización
*/
const getTypeIcons = () => [
  {
    id: `section`,
    content: <img src={sectionquestionicon} alt="Sección" title="Sección" />,
  },
  {
    id: `title`,
    content: <img src={titlequestionicon} alt="Título" title="Título" />,
  },
  {
    id: `subtitle`,
    content: (
      <img src={subtitlequestionicon} alt="Subtítulo" title="Subtítulo" />
    ),
  },
  {
    id: `attachment`,
    content: <img src={attachmentquestionicon} alt="Imágen" title="Imágen" />,
  },
  {
    id: `text`,
    content: <img src={textquestionicon} alt="Texto" title="Texto" />,
  },
  {
    id: `multiplechoice`,
    content: <img src={multiplechoicequestionicon} alt="Lista" title="Lista" />,
  },
  {
    id: `observation`,
    content: (
      <img src={obsquestionicon} alt="Observación" title="Observación" />
    ),
  },
];
/*
    Reordena una lista en caso de arrastrar elementos en un mismo contenedor
    No aplicable a Selectores de tipos.
*/
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  if (startIndex !== endIndex) {
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  }
  return result;
};

/*
    Componente contenedor de tipo de preguntas. Generador y manipulador de tipos de preguntas.
*/
export default function CompoundQuestion(props) {
  /* Listado de tipos de preguntas */
  const [items] = React.useState(getTypeIcons());
  /* Listado de elementos seleccionados. Generado a partir de arrastrar y soltar tipos de preguntas */
  const [selected, setSelected] = React.useState([]);
  /* Listados de elementos a arrastrar y elementos arrastrados */
  const [id2List] = React.useState({
    droppable: "items",
    droppable2: "selected",
  });
  /* Listados paralelo y sincronizado con selected para mantener un listado simplificado de preguntas (y respuestas) */
  const [questions, setQuestions] = React.useState([]);

  /*
        Obtener componentes que serán parte de la lista selected
        utilizada en carga de datos (Edit) y en drop de nuevos tipos
    */
  const getComponentItem = (
    type,
    key,
    setFromChild,
    content,
    setDisableSaveButton
  ) => {
    let newItem = null;
    switch (type) {
      case "text":
        newItem = {
          id: `item-${key}-result`,
          key: key,
          type: "TextQuestion",
          content: (
            <TextQuestion
              subType={"text"}
              setFromChild={setFromChild}
              id={key}
              content={content}
              description={"Texto"}
              icon={<img src={textquestionicon} alt="Texto" title="Texto" />}
            />
          ),
        };
        break;
      case "multiplechoice":
        newItem = {
          id: `item-${key}-result`,
          key: key,
          type: "MultipleChoiceQuestion",
          content: (
            <MultipleChoiceQuestion
              setFromChild={setFromChild}
              id={key}
              content={content}
              description={"Lista"}
              icon={
                <img
                  src={multiplechoicequestionicon}
                  alt="Lista"
                  title="Lista"
                />
              }
            />
          ),
        };
        break;
      case "attachment":
        newItem = {
          id: `item-${key}-result`,
          key: key,
          type: "AttachmentQuestion",
          content: (
            <AttachmentQuestion
              setFromChild={setFromChild}
              id={key}
              content={content}
              description={"Imágen"}
              setDisableSaveButton={setDisableSaveButton}
              icon={
                <img src={attachmentquestionicon} alt="Imágen" title="Imágen" />
              }
            />
          ),
        };
        break;
      case "observation":
        newItem = {
          id: `item-${key}-result`,
          key: key,
          type: "TextQuestion",
          content: (
            <TextQuestion
              subType={"observation"}
              setFromChild={setFromChild}
              id={key}
              content={content}
              description={"Observación"}
              icon={
                <img
                  src={obsquestionicon}
                  alt="Observación"
                  title="Observación"
                />
              }
            />
          ),
        };
        break;
      case "section":
        newItem = {
          id: `item-${key}-result`,
          key: key,
          type: "TextQuestion",
          content: (
            <TextQuestion
              subType={"section"}
              setFromChild={setFromChild}
              id={key}
              content={content}
              description={"Sección"}
              icon={
                <img src={sectionquestionicon} alt="Sección" title="Sección" />
              }
            />
          ),
        };
        break;
      case "title":
        newItem = {
          id: `item-${key}-result`,
          key: key,
          type: "TextQuestion",
          content: (
            <TextQuestion
              subType={"title"}
              setFromChild={setFromChild}
              id={key}
              content={content}
              description={"Título"}
              icon={<img src={titlequestionicon} alt="Título" title="Título" />}
            />
          ),
        };
        break;
      case "subtitle":
        newItem = {
          id: `item-${key}-result`,
          key: key,
          type: "TextQuestion",
          content: (
            <TextQuestion
              subType={"subtitle"}
              setFromChild={setFromChild}
              id={key}
              content={content}
              description={"Subtítulo"}
              icon={
                <img
                  src={subtitlequestionicon}
                  alt="Subtítulo"
                  title="Subtítulo"
                />
              }
            />
          ),
        };
        break;
      default:
        newItem = {
          id: `item-${key}-result`,
          key: key,
          type: "TextQuestion",
          content: (
            <TextQuestion
              subType={"text"}
              setFromChild={setFromChild}
              id={key}
              content={content}
            />
          ),
        };
        break;
    }
    return newItem;
  };

  /*
        Efecto utilizado para cargar datos en caso de Edit
        se utiliza props.content porque hay una inicialización en vacio
    */
  useEffect(() => {
    function loadSection() {
      if (props.content && props.content.length > 0) {
        let selectDummy = [];
        let questionsDummy = [];

        props.content.map((item, key) => {
          if (item) {
            let newItem = null;
            newItem = getComponentItem(
              item.type,
              key,
              setFromChild,
              item.content,
              props.setDisableSaveButton
            );
            selectDummy.push(newItem);
            questionsDummy.push(item);
          }
          return true;
        });
        setQuestions(questionsDummy);
        sessionStorage.setItem(
          "teracodeExamsQuestions",
          JSON.stringify(questionsDummy)
        );
        setTimeout(() => {
          setSelected(selectDummy);
        }, 500);
      }
    }
    loadSection();
  }, [props.content]);

  /*
        Efecto utilizado para propagar el cambio de una pregunta (agregado, cambio de contenido) al contenedor padre.
    */
  useEffect(() => {
    props.setCompoundQuestions(questions);
  }, [questions]);

  /*
        Efecto utilizado para sincronizar selected con questions
    */
  useEffect(() => {
    sessionStorage.setItem("teracodeExamsSelected", JSON.stringify(selected));

    let qDummy = [...questions];
    let qDummyDummy = [];
    let sDummy = [...selected];
    sDummy.map((sItem, sIndex) => {
      let exist = false;
      qDummy.map((qItem, qIndex) => {
        if (qItem.id === sItem.key) {
          qDummyDummy[sIndex] = qItem;
          exist = true;
        }
        return true;
      });
      if (!exist) {
        //switch (sItem.content.type.name) {
        switch (sItem.type) {
          case "TextQuestion":
            qDummyDummy[sIndex] = {
              id: sIndex,
              type: "text",
              content: { description: "" },
            };
            break;
          case "MultipleChoiceQuestion":
            qDummyDummy[sIndex] = {
              id: sIndex,
              type: "multiplechoice",
              content: { description: "", multipleChoiceOptions: "" },
            };
            break;
          case "AttachmentQuestion":
            qDummyDummy[sIndex] = {
              id: sIndex,
              type: "attachment",
              content: { description: "", files: [] },
            };
            break;
        }
      }
      return true;
    });
    setQuestions(qDummyDummy);
    sessionStorage.setItem(
      "teracodeExamsQuestions",
      JSON.stringify(qDummyDummy)
    );
  }, [selected]);

  /*
        Funcion utilizada por componentes tipo hijos para propagar sus cambios de valores a este componente

        Utilizamos storage porque no logramos una consistencia en los valores de los estados al agregar los componentes
        de manera dinamica
    */
  function setFromChild(childRef, content) {
    let sDummy = JSON.parse(sessionStorage.getItem("teracodeExamsSelected")); //[...selected];
    let qDummy = JSON.parse(sessionStorage.getItem("teracodeExamsQuestions")); //[...questions];
    let qDummyDummy = JSON.parse(
      sessionStorage.getItem("teracodeExamsQuestions")
    ); //[...questions];
    sDummy.map((sItem, sIndex) => {
      if (qDummy.length > 0) {
        qDummy.map((qItem, qIndex) => {
          if (sItem.key === childRef && sIndex === qIndex) {
            qDummyDummy[qIndex] = content;
          }
          return true;
        });
      } else {
        qDummyDummy[childRef] = content;
      }
      return true;
    });
    sessionStorage.setItem(
      "teracodeExamsQuestions",
      JSON.stringify(qDummyDummy)
    );
    setQuestions(qDummyDummy);
  }

  /*
        Funciones relativas a D&D
    */
  function move(source, destination, droppableSource, droppableDestination) {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    let newItem = null;

    newItem = getComponentItem(
      removed.id,
      selected.length,
      setFromChild,
      [],
      props.setDisableSaveButton
    );

    destClone.splice(droppableDestination.index, 0, newItem);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  }

  function deleteSection(list, index) {
    let l = [...list];
    l.splice(index, 1);
    setSelected(l);

    let q = JSON.parse(sessionStorage.getItem("teracodeExamsQuestions")); //[...questions];
    q.splice(index, 1);
    setQuestions(q);
    sessionStorage.setItem("teracodeExamsQuestions", JSON.stringify(q));
    return true;
  }

  function getList(id) {
    if (id2List[id] === "items") {
      return items;
    } else {
      return selected;
    }
  }

  function onDragEnd(result) {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId !== "droppable") {
        const items = reorder(
          getList(source.droppableId),
          source.index,
          destination.index
        );
        setSelected(items);

        setTimeout(() => {
          const qReorder = reorder(questions, source.index, destination.index);
          setQuestions(qReorder);
          sessionStorage.setItem(
            "teracodeExamsQuestions",
            JSON.stringify(qReorder)
          );
        }, 500);
      }
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );
      setSelected(result.droppable2);
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {/*
                TODO: Revisar: seteo de estados en storage. Problema con el uso de estados en funcion de propagacion
            */}
      <div className="component-bar">
        <div className="compound-question-title">Secciones:</div>
        <div className="button-footer">
          <Button className="btn-cancel" onClick={props.onClose}>
            Cancelar
          </Button>
          <Button className="btn-accept" onClick={props.handleSave}>
            Guardar
          </Button>
          {props.questionId && (
            <Button className="btn-accept" onClick={props.handleViewPdf}>
              Ver PDF
            </Button>
          )}
        </div>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div className="compound-origin" ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="compound-origin-item"
                    >
                      {item.content}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      <Droppable droppableId="droppable2">
        {(provided, snapshot) => (
          <div className="compound-result" ref={provided.innerRef}>
            {selected.length === 0 && (
              <div className="drop_msg">Drop sections here!</div>
            )}
            {selected.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="compound-result-item"
                  >
                    <div>
                      <div className="question-number">
                        <div className="section-number-rounded">
                          {index + 1}
                        </div>
                      </div>
                      <div
                        className="question-remove-rounded"
                        onClick={() => deleteSection(selected, index, item.key)}
                      >
                        X
                      </div>
                    </div>
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
