import React from 'react';
import {API, FILE_URI} from "../../../Constants";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import Typography from '@material-ui/core/Typography';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileWord, faFilePdf, faFile} from '@fortawesome/free-solid-svg-icons'
import textService from '../../../services/TextService.js';


export default function AttachmentQuestion(props) {

    const filesTypes = {
        'image/png': 'image',
        'image/jpeg': 'image',
        'application/pdf': 'pdf',
        'application/msword': 'doc',
        'text/plain': 'doc',
        'application/vnd.ms-excel': 'doc'
    };

    function parseName(fullName) {
        const name = fullName.split("ART");
        return name[1];
    }

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                { textService.formatBreakLines(props.content.description)}
            </Typography>
            <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-3">
                {
                    props.content.files.map((item, key) => {
                        if (filesTypes[item.type] === 'image') {
                            return (
                                <div className="attach-ro-container" key={key} ><img src={API + FILE_URI + item.uri} alt={item.uri}
                                                                          className="attach-ro-image"/>
                                    <div className="attach-ro-footer">{parseName(item.name)}</div>
                                </div>)
                        } else {
                            switch (filesTypes[item.type]) {
                                case 'pdf':
                                    return (<div className="attach-ro-container" key={key} >
                                        <div className="attach-ro-doc"><a
                                            href={API + FILE_URI + item.uri}><FontAwesomeIcon icon={faFilePdf}
                                                                                              size="7x"/></a></div>
                                        <div className="attach-ro-footer">{parseName(item.name)}</div>
                                    </div>);
                                case 'doc':
                                    return (<div className="attach-ro-container" key={key} >
                                        <div className="attach-ro-doc"><a
                                            href={API + FILE_URI + item.uri}><FontAwesomeIcon icon={faFileWord}
                                                                                              size="7x"/></a></div>
                                        <div className="attach-ro-footer">{parseName(item.name)}</div>
                                    </div>);
                                default:
                                    return (<div className="attach-ro-container" key={key} >
                                        <div className="attach-ro-doc"><a
                                            href={API + FILE_URI + item.uri}><FontAwesomeIcon icon={faFile} size="7x"/></a>
                                        </div>
                                        <div className="attach-ro-footer">{parseName(item.name)}</div>
                                    </div>);
                            }
                        }
                    })

                }
            </div>
        </React.Fragment>
    )

}
