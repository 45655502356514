import {API} from '../Constants';

const apiService = {
    httpGet: async (endpoint) => {
        const bearerToken = sessionStorage.getItem('teracodeExamsToken') ? 'Bearer ' + sessionStorage.getItem('teracodeExamsToken') : '';
        const payload = {
            method: 'get',
            headers: {
                Authorization: bearerToken,
                //locale: sessionStorage.getItem('teracodeExamsLanguage')
            },
        };

        return fetch(`${API}${endpoint}`,
            payload)
            .then(response => response.json())
            .then(response => response)
            .catch(error => {
                throw Error(error);
            });
    },

    httpPost: async (endpoint, data) => {
        const bearerToken = sessionStorage.getItem('teracodeExamsToken') ? 'Bearer ' + sessionStorage.getItem('teracodeExamsToken') : '';
        const payload = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: bearerToken,
                //locale: sessionStorage.getItem('teracodeExamsLanguage')
            },
            body: JSON.stringify(data)
        };
        return fetch(`${API}${endpoint}`,
            payload)
            .then(response => {
                response = response.json();
                return response;
            })
            .then(response => {
                return response;
            })
            .catch(error => {
                return error;
            });
    },

    httpDelete: async (endpoint) => {
        const bearerToken = sessionStorage.getItem('teracodeExamsToken') ? 'Bearer ' + sessionStorage.getItem('teracodeExamsToken') : '';
        const payload = {
            method: 'delete',
            headers: {
                Authorization: bearerToken,
                //locale: sessionStorage.getItem('teracodeExamsLanguage')
            },
        };
        return fetch(`${API}${endpoint}`,
            payload)
            .then(response => response.json())
            .then(response => response)
            .catch(error => {
                throw Error(error);
            });
    },

    httpPut: async (endpoint, data) => {
        const bearerToken = sessionStorage.getItem('teracodeExamsToken') ? 'Bearer ' + sessionStorage.getItem('teracodeExamsToken') : '';
        const payload = {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                Authorization: bearerToken,
                //locale: sessionStorage.getItem('teracodeExamsLanguage')
            },
            body: JSON.stringify(data)
        };
        return fetch(`${API}${endpoint}`,
            payload)
            .then(response => {
                response = response.json();
                return response;
            })
            .then(response => {
                return response;
            })
            .catch(error => {
                return error;
            });
    }
};

export default apiService;