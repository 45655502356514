import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import '../assets/styles/login.scss';

import Button from '@material-ui/core/Button';
import logo from '../assets/images/logogalindez.png';
import CircularProgress from "@material-ui/core/CircularProgress";

class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.auth.submitLogin(this.state);
  };

  render() {
    const { userToken, authError } = this.props.auth;

    if (userToken) return <Redirect to="/" />;

    return (
      <header className="login-container">
        <form onSubmit={this.handleSubmit} className="login-form">
          <div>
            <img src={logo} alt="Presupuestos"/>
          </div>
          <h2>
            Presupuestos
          </h2>
          <div className="input-field">
            <label htmlFor="username">
                Usuario:
                </label>
            <input
              type="text"
              id="username"
              value={this.state.username}
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="input-field">
            <label htmlFor="password">
                Password
            : </label>
            <input
              type="password"
              id="password"
              value={this.state.password}
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="input-field">
            {this.props.waitLogin ? <CircularProgress size={24} className='buttonProgress'/> :
              <Button
                type="submit"
                className="btn-accept"
              >
                  Ingresar
              </Button>
            }
          </div>
          {
          authError && <div className="error-text">
            <p>{authError}</p>
          </div>
          }
        </form>
      </header>
    );
  }
}

export default LogIn;
