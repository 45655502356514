import React, { Component } from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import LogIn from "./components/LogIn";
import Navbar from "./components/layout/Navbar";
import { API, LOGIN_URI } from "./Constants";
import QuestionList from "./components/question/QuestionList";
import QuestionForm from "./components/question/QuestionForm";

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Bai Jamjuree"',
  },
  overrides: {
    MuiStepIcon: {
      root: {
        "&$active": {
          color: "#c94c4c",
        },
        "&$completed": {
          color: "#4CAF50",
        },
      },
    },
    MuiButton: {
      contained: {
        color: "#fff",
        backgroundColor: "#4CAF50",
        "&:hover": {
          backgroundColor: "#ff0000",
        },
      },
    },
    MuiInputBase: {
      inputMultiline: {
        border: "solid 1px #CCC",
        padding: "5px",
      },
    },
  },
});

class App extends Component {
  constructor() {
    super();

    const language = navigator.language.split(/[-_]/)[0];

    this.state = {
      userToken: sessionStorage.getItem("teracodeExamsToken"),
      authError: "",
      language: language,
      waitLogin: false,
    };
  }

  submitLogin = (credentials) => {
    this.setState({
      authError: "",
      waitLogin: true,
    });

    const payload = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(credentials),
    };

    fetch(API + LOGIN_URI, payload)
      .then((response) => {
        if (response.status === 401) {
          this.setState({
            authError: "Incorrect username or password",
            waitLogin: false,
          });
          return;
        } else if (response.status !== 200) {
          this.setState({
            authError: "There was an error with the application",
            waitLogin: false,
          });
          return;
        }
        return response.json();
      })
      .then((response) => {
        if (response.loginok === "OK") {
          this.setState({
            userToken: response.token,
            authError: "",
            waitLogin: false,
          });
          sessionStorage.setItem("teracodeExamsToken", this.state.userToken);
        } else {
          this.setState({
            authError: response.msg,
            waitLogin: false,
          });
          return;
        }
      })
      .catch((err) => {
        this.setState({
          authError: "Server is not responding.",
          waitLogin: false,
        });
        return err;
      });
  };

  signOut = () => {
    sessionStorage.removeItem("teracodeExamsToken");
    this.setState({
      userToken: "",
    });
  };

  handleLanguageChange = (ln) => {
    this.setState({ language: ln });
  };

  render() {
    const auth = {
      userToken: this.state.userToken,
      authError: this.state.authError,
      submitLogin: this.submitLogin,
    };

    return (
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          {/*
          <Route
            path="/candidateexam/:candidateId/:examId"
            component={CandidateExam}
          />

        */}
          <div className="App">
            <Navbar
              userToken={this.state.userToken}
              signOut={this.signOut}
              handleLanguageChange={this.handleLanguageChange}
              language={this.state.language}
            />
            <div className="main-container">
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => <Redirect to="/questions" />}
                />
                <Route
                  exact
                  path="/question"
                  render={(props) =>
                    this.state.userToken ? (
                      <QuestionForm
                        {...props}
                        userToken={this.state.userToken}
                      />
                    ) : (
                      <Redirect to="/signin" />
                    )
                  }
                />
                <Route
                  exact
                  path="/question/:id"
                  render={(props) =>
                    this.state.userToken ? (
                      <QuestionForm
                        {...props}
                        userToken={this.state.userToken}
                      />
                    ) : (
                      <Redirect to="/signin" />
                    )
                  }
                />
                <Route
                  exact
                  path="/questions"
                  render={(props) =>
                    this.state.userToken ? (
                      <QuestionList
                        {...props}
                        userToken={this.state.userToken}
                      />
                    ) : (
                      <Redirect to="/signin" />
                    )
                  }
                />
                <Route
                  path="/signin"
                  render={(props) => (
                    <LogIn
                      {...props}
                      auth={auth}
                      waitLogin={this.state.waitLogin}
                    />
                  )}
                />
              </Switch>
            </div>
          </div>
        </BrowserRouter>
      </MuiThemeProvider>
    );
  }
}

export default App;
