//export const BACKURL = "http://galindez.localhost";
export const BACKURL = "http://galindez.vanrec.com.ar";
export const API = `${BACKURL}/api`;
export const LOGIN_URI = "/login";
export const ANSWER_URI = "/candidateexam";
export const USERS_URI = "/user/internal_user";
export const CANDIDATES_URI = "/user/candidate";
export const CANDIDATES_EXAM_URI = "/user/candidate/exams";
export const EXAMS_URI = "/exam";
export const QUESTIONS_URI = "/question";
export const CLIENTES_URI = "/clientes";
export const TAGS_URI = "/question/tags";
export const CANDIDATES_ANSWER_URI = "/candidateexam/answer";
export const REVIEWS_URI = "/review";
export const FILE_URI = "/file";
export const validEmailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
