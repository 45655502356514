import React, { useEffect, useCallback } from "react";
import { API } from "../../../Constants";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import { FILE_URI } from "../../../Constants";
import apiService from "../../../services/Api.service";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";

import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import SliderAlert from "./../../utils/SliderAlert";

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateType);

export default function AttachmentQuestion(props) {
  const [pond, setPond] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [showSlideMsg, setShowSlideMsg] = React.useState(false);
  const [slideMsg, setSlideMsg] = React.useState("");
  const [snackClass, setSnackClass] = React.useState("");
  const bearerToken = sessionStorage.getItem("teracodeExamsToken")
    ? "Bearer " + sessionStorage.getItem("teracodeExamsToken")
    : "";

  const updateFilesInParent = useCallback(() => {
    props.setFromChild(props.id, {
      id: props.id,
      type: "attachment",
      content: { files: files },
    });
  }, [props, files]);

  useEffect(() => {
    function loadFiles() {
      if (props.content && props.content.files) {
        let filesDummy = [];
        props.content.files.map((file) => {
          pond.addFile(file.name, {
            type: "local",
          });
          const dataFile = {
            name: file.name,
            uri: "/download/" + file.name,
            type: file.type,
            size: file.size,
          };
          filesDummy.push(dataFile);
          return true;
        });
        setFiles(filesDummy);
        setTimeout(() => {
          props.setDisableSaveButton(false);
        }, 500);
      }
    }

    if (pond) {
      loadFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pond]);

  useEffect(() => {
    function loadFiles() {
      if (props.content && props.content.files) {
        let filesDummy = [];
        props.content.files.map((file) => {
          pond.addFile(file.name, {
            type: "local",
          });
          const dataFile = {
            name: file.name,
            uri: "/download/" + file.name,
            type: file.type,
            size: file.size,
          };
          filesDummy.push(dataFile);
          return true;
        });
        setFiles(filesDummy);
        setTimeout(() => {
          props.setDisableSaveButton(false);
        }, 500);
      }
    }

    if (pond) {
      loadFiles();
    }
  }, [props]);

  useEffect(() => {
    //updateFilesInParent();
  }, [pond, files, updateFilesInParent]);

  function showErrorMsgFN(msg, state) {
    setShowSlideMsg(true);
    setSlideMsg(msg);
    setSnackClass(state);
  }

  function handleCloseSlideMsg() {
    setShowSlideMsg(false);
  }
  return (
    <React.Fragment>
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-age-simple">
          {props.icon}{" "}
          <span className="compound-title">{props.description}</span>
        </InputLabel>
      </FormControl>
      <FormControl variant="outlined">
        <FilePond
          labelIdle='Arrastre su archivo aquí o <span class="filepond--label-action"> Seleccione </span> (Max 1 files)'
          ref={(ref) => setPond(ref)}
          allowMultiple={true}
          maxFiles={1}
          maxParallelUploads={1}
          acceptedFileTypes={[
            "image/png",
            "image/jpeg",
            "application/pdf",
            "application/msword",
            "text/plain",
            "application/vnd.ms-excel",
          ]}
          server={{
            url: `${API}/file`,
            /*
                        headers: {
                            Authorization: bearerToken,
                            locale: sessionStorage.getItem('teracodeExamsLanguage')
                        },
                        */
            process: {
              url: "/process",
              onload: (response) => {
                response = JSON.parse(response);
                let filesDummy = [...files];
                const dataFile = {
                  name: response.name,
                  uri: "/download/" + response.name,
                  type: response.type,
                  size: response.size,
                };
                filesDummy.push(dataFile);
                setFiles(filesDummy);
              },
            },
            revert: null,
            restore: "/restore/",
            load: "/load/",
            fetch: "/fetch/",
          }}
          onprocessfile={() => {
            setTimeout(() => {
              updateFilesInParent();
              props.setDisableSaveButton(false);
            }, 500);
          }}
          beforeAddFile={(currentFile) => {
            const files = pond.getFiles();
            let ocurrences = 0;
            files.map((file) => {
              if (currentFile.file.name === file.file.name) {
                ocurrences++;
              }
              return true;
            });
            if (ocurrences > 1) {
              showErrorMsgFN("Duplicated filename", "error");
              return false;
            }
          }}
          onaddfile={() => {
            //updateFilesInParent();
            props.setDisableSaveButton(true);
          }}
          onremovefile={(error, file) => {
            const dataFile = {
              name: file.filename,
              uri: "/download/" + file.filename,
              type: file.fileType,
              size: file.fileSize,
            };
            apiService.httpPost(FILE_URI + "/revert", dataFile).then((data) => {
              if (!data.errors) {
                let filesDummy = [];
                pond.getFiles().map((file) => {
                  const dataFile = {
                    name: file.filename,
                    uri: "/download/" + file.filename,
                    type: file.fileType,
                    size: file.fileSize,
                  };
                  filesDummy.push(dataFile);
                  return true;
                });
                setFiles([...filesDummy]);
                updateFilesInParent();
              }
            });
          }}
        />
        <SliderAlert
          showSlideMsg={showSlideMsg}
          snackClass={snackClass}
          slideMsg={slideMsg}
          handleCloseSlideMsg={handleCloseSlideMsg}
        />
      </FormControl>
    </React.Fragment>
  );
}
