import React from 'react';
import textService from '../../../services/TextService.js';

export default function TextQuestion(props) {
  return (
    <React.Fragment>
        <h6 className="MuiTypography-root MuiTypography-h6 MuiTypography-gutterBottom">{ textService.formatBreakLines(props.content.description)}</h6>
    </React.Fragment>
  );
}
