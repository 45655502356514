import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

class SignedInLinks extends Component {
  constructor(props) {
    super(props);
    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.state = {
      isHovering: false,
      signOut: props.signOut,
      language: props.language,
      handleLanguageChange: props.handleLanguageChange,
    };
  }

  handleMouseHover() {
    this.setState(this.toggleHoverState);
  }

  toggleHoverState() {
    return {
      isHovering: !this.state.isHovering,
    };
  }

  handleChange = (event) => {
    this.state.handleLanguageChange(event.target.value);
  };

  render() {
    return (
      <div className="navbar-items">
        <ul>
          <li
            onMouseEnter={this.handleMouseHover}
            onMouseLeave={this.handleMouseHover}
          >
            <NavLink
              to="/questions"
              className="item"
              activeClassName="itemSelected"
            >
              Presupuestos
            </NavLink>
          </li>
          <li>
            <NavLink to="/" className="item" onClick={this.state.signOut}>
              Logout
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}

export default SignedInLinks;
