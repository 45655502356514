import React, { useEffect, useState } from "react";

import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import apiService from "../../services/Api.service";
import { QUESTIONS_URI } from "../../Constants";
import { WithContext as ReactTags } from "react-tag-input";
import CompoundQuestionReadOnly from "./types/CompoundQuestionReadOnly";

const useStyles = makeStyles(() => ({
  cardGrid: {
    width: "auto",
    margin: "auto",
    padding: "0",
    maxWidth: "unset",
  },
  cardExam: {
    width: "100%",
    textAlign: "left",
  },
  cardList: {
    display: "flex",
  },
  leftColumn: {
    flex: "75%",
  },
  separator: {
    borderTop: "1px solid rgba(34,36,38,.15)",
    borderBottom: "1px solid rgba(255,255,255,.1)",
  },
}));

export default function QuestionInfo(props) {
  const classes = useStyles();
  const [questionId] = useState(props.questionId);
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [questionTags, setQuestionTags] = useState([]);
  const [questionContent, setQuestionContent] = useState(null);

  useEffect(() => {
    apiService.httpGet(QUESTIONS_URI + "/" + questionId).then((data) => {
      if (data.errors) {
      } else {
        setQuestionTitle(data.title);
        setQuestionType(data.answerType);
        setQuestionTags(
          data.tagResponses.map((tag) => {
            return {
              id: tag.id.toString(),
              text: tag.tag,
            };
          })
        );
        setQuestionContent(data.content);
      }
    });
  }, [questionId]);

  return (
    <div className="simplelist-container infoModal">
      <Container className={classes.cardGrid}>
        <Card className={classes.cardExam}>
          <CardContent>
            <div className={classes.cardList}>
              <div className={classes.leftColumn}>
                <div>
                  <Typography variant="h6" gutterBottom>
                    Title :
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {questionTitle}
                  </Typography>
                </div>
                <div>
                  <Typography variant="h6" gutterBottom>
                    Tags :
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    <ReactTags
                      tags={questionTags}
                      suggestions={[]}
                      readOnly={true}
                    />
                  </Typography>
                </div>
                <div>
                  <div className={classes.separator} />
                  <div className="exam-compound-question">
                    <CompoundQuestionReadOnly
                      content={JSON.parse(questionContent)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
