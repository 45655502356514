import React, { useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import { WithContext as ReactTags } from "react-tag-input";

import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";

export default function MultipleChoiceQuestion(props) {
  const [description, setDescription] = React.useState("");
  const [multipleChoiceOptions, setMultipleChoiceOptions] = React.useState([]);

  useEffect(() => {
    if (
      props.content.multipleChoiceOptions &&
      props.content.multipleChoiceOptions.length > 0
    ) {
      setDescription(props.content.description);
      setMultipleChoiceOptions(props.content.multipleChoiceOptions);
    }
  }, [props.content.description, props.content.multipleChoiceOptions]);

  useEffect(() => {
    if (multipleChoiceOptions.length > 0) {
      props.setFromChild(props.id, {
        id: props.id,
        type: "multiplechoice",
        content: {
          description: description,
          multipleChoiceOptions: multipleChoiceOptions,
        },
      });
    }
  }, [description, multipleChoiceOptions]);

  function handleDeleteMultipleChoiceOptions(i) {
    const multipleChoiceOptionsDummy = multipleChoiceOptions.slice(0);
    multipleChoiceOptionsDummy.splice(i, 1);
    setMultipleChoiceOptions(multipleChoiceOptionsDummy);
  }

  function handleAdditionMultipleChoiceOptions(option) {
    const multipleChoiceOptionsDummy = [].concat(multipleChoiceOptions, option);
    setMultipleChoiceOptions(multipleChoiceOptionsDummy);
  }

  return (
    <React.Fragment>
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-age-simple">
          {props.icon}{" "}
          <span className="compound-title">{props.description}</span>
        </InputLabel>
      </FormControl>
      <FormControl component="fieldset" className="multiple-choice-container">
        {(placeholder) => (
          <ReactTags
            tags={multipleChoiceOptions}
            placeholder={placeholder}
            suggestions={[]}
            autofocus={false}
            handleDelete={handleDeleteMultipleChoiceOptions}
            handleAddition={handleAdditionMultipleChoiceOptions}
          />
        )}
      </FormControl>
    </React.Fragment>
  );
}
