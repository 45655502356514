import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import "../../assets/styles/simplelist.scss";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import ArchiveDeleteDialog from "./ArchiveDeleteDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import QuestionInfoModal from "../question/QuestionInfoModal";

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [],
      data: [],
      titleToWork: "",
      showArchiveDeleteDialog: false,
      showUnarchiveDialog: false,
      idToWork: "",
      showArchived: false,
      showQuestionInfoModal: false,
      showExamInfoModal: false,
    };
  }

  handleSwitch = () => {
    this.setState({
      showArchived: !this.state.showArchived,
    });
  };

  createSwitch = () => {
    if (this.props.options.switch) {
      return "Show archived";
    }
  };

  createButton = () => {
    return (
      <Button
        type="button"
        className="btn-accept"
        href={this.props.options.href}
      >
        {this.props.options.btnAddTitle}
      </Button>
    );
  };

  handleArchiveDelete = (id, title) => {
    this.setState({
      titleToWork: title,
      showArchiveDeleteDialog: true,
      idToWork: id,
    });
  };

  handleUnarchived = (id, title) => {
    this.setState({
      titleToWork: title,
      showUnarchiveDialog: true,
      idToWork: id,
    });
  };

  closeArchiveDeleteDialog = () => {
    this.setState({
      titleToWork: "",
      showArchiveDeleteDialog: false,
      idToWork: "",
    });
  };

  closeUnarchiveDialog = () => {
    this.setState({
      titleToWork: "",
      showUnarchiveDialog: false,
      idToWork: "",
    });
  };

  action = () => {
    this.props.options.action(this.state.idToWork);
    this.closeArchiveDeleteDialog();
  };

  unarchiveAction = () => {
    this.props.options.unarchiveAction(this.state.idToWork);
    this.closeUnarchiveDialog();
  };

  openQuestionInfoModal = (elementId) => {
    this.setState({
      showQuestionInfoModal: true,
      idToWork: elementId,
    });
  };

  openExamInfoModal = (elementId) => {
    this.setState({
      showExamInfoModal: true,
      idToWork: elementId,
    });
  };

  closeQuestionInfoModal = () => {
    this.setState({
      showQuestionInfoModal: false,
      idToWork: "",
    });
  };

  closeExamInfoModal = () => {
    this.setState({
      showExamInfoModal: false,
      idToWork: "",
    });
  };

  render() {
    const items = this.props.items;
    const columns = [];

    const data =
      items && items.length > 0
        ? items.map((item, key) => {
            const simpleListCard = {};
            item.map(function (column) {
              if (column.identifier) {
                simpleListCard["identifier"] = column.fieldValue;
              } else {
                simpleListCard[column.fieldName] = column.fieldValue;
                if (key === 0) {
                  if (column.fieldType !== "hidden") {
                    columns.push({
                      title: column.fieldLabel,
                      field: column.fieldName,
                    });
                  }
                }
              }
              return true;
            });
            return simpleListCard;
          })
        : [];

    const filteredData = data.filter((value) => {
      return this.state.showArchived || value.deleted === "false";
    });

    const filteredColumns = filteredData.length > 0 ? columns : [];

    const actions = [];
    if (filteredData.length > 0) {
      actions.push((rowData) => ({
        icon: "filter_none",
        tooltip: "Duplicar",
        onClick: (event, rowData) => {
          this.props.options.duplicar(rowData.identifier);
        },
      }));
      actions.push((rowData) => ({
        icon: () => {
          if (rowData.locked === "true") {
            return (
              <i className="material-icons edit-question disabled">lock</i>
            );
          } else if (rowData.deleted === "false") {
            return <i className="material-icons edit-question">create</i>;
          } else {
            return (
              <i className="material-icons edit-question disabled">create</i>
            );
          }
        },
        disabled: rowData.locked === "true" || rowData.deleted === "true",
        onClick: (event, rowData) => {
          if (rowData.locked !== "true" || rowData.deleted === "true") {
            this.props.history.push(
              this.props.options.editUrl + "/" + rowData.identifier
            );
          }
        },
      }));
      actions.push((rowData) => ({
        icon: () => {
          if (rowData.locked === "true") {
            return "";
          } else if (rowData.deleted !== "true") {
            return (
              <i className="material-icons edit-question">
                {this.props.options.method}
              </i>
            );
          } else {
            return <i className="material-icons edit-question">unarchive</i>;
          }
        },
        className: rowData.locked === "true" ? "lockedButton" : "",
        disabled: rowData.locked === "true",
        onClick: (event, rowData) => {
          if (rowData.deleted === "false") {
            this.handleArchiveDelete(rowData.identifier, rowData.title);
          } else {
            this.handleUnarchived(rowData.identifier, rowData.title);
          }
        },
      }));
    }

    console.log(actions);

    return (
      <div className="simplelist-container">
        <div className="simplelist-title">
          <h3>{this.props.options.title}</h3>
          <div className="btn-container">
            {this.createSwitch()}
            {this.createButton()}
          </div>
        </div>
        <MaterialTable
          title=""
          columns={filteredColumns}
          data={filteredData}
          actions={actions}
          options={{
            actionsColumnIndex: -1,
            pageSize: 10,
            rowStyle: (rowData) => ({
              backgroundColor:
                rowData.locked === "true"
                  ? "whitesmoke"
                  : rowData.deleted === "true"
                  ? "antiquewhite"
                  : "",
            }),
          }}
        />
        <ArchiveDeleteDialog
          show={this.state.showArchiveDeleteDialog}
          close={this.closeArchiveDeleteDialog}
          header={this.props.options.dialogTitle}
          btnAction={this.props.options.btnAction}
          action={this.action}
          title={this.state.titleToWork}
          body={this.props.options.method}
        />
        <ArchiveDeleteDialog
          show={this.state.showUnarchiveDialog}
          close={this.closeUnarchiveDialog}
          header={"Unarchive Exam"}
          btnAction={"Unarchive"}
          action={this.unarchiveAction}
          title={this.state.titleToWork}
          body={"unarchive"}
        />
        <QuestionInfoModal
          open={this.state.showQuestionInfoModal}
          questionId={this.state.idToWork}
          handleClose={this.closeQuestionInfoModal}
        />
      </div>
    );
  }
}

UserList.contextTypes = {
  intl: PropTypes.object,
};

export default withRouter(UserList);
