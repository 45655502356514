import React, { useCallback, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { BACKURL, QUESTIONS_URI, CLIENTES_URI } from "../../Constants";
import { Redirect } from "react-router-dom";
import apiService from "../../services/Api.service.js";
import SliderAlert from "./../utils/SliderAlert";
import Paper from "@material-ui/core/Paper";
import "../../assets/styles/question.scss";
import CompoundQuestion from "./types/CompoundQuestion";
import Grid from "@material-ui/core/Grid";
import AttachmentQuestion from "./types/AttachmentQuestion";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

const KeyCodes = {
  comma: 188,
  enter: 13,
  tab: 9,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.tab];

export default function QuestionForm(props) {
  const [cliente, setCliente] = React.useState("");
  const [clienteData, setClienteData] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [pie, setPie] = React.useState("");
  const [logo, setLogo] = React.useState("");

  const [redirect] = React.useState(false);
  const [questionId] = React.useState(
    props.match ? props.match.params.id : false
  );
  const [showSlideMsg, setShowSlideMsg] = React.useState(false);
  const [slideMsg, setSlideMsg] = React.useState("");
  const [snackClass, setSnackClass] = React.useState("");
  const [disableSaveButton, setDisableSaveButton] = React.useState(false);
  const [compoundQuestions, setCompoundQuestions] = React.useState([]);
  const [content, setContent] = React.useState([]);
  const [onClose] = React.useState(() =>
    props.handleClose ? props.handleClose : handleClose
  );
  const [onSave] = React.useState(() =>
    props.saveQuestion ? props.saveQuestion : saveQuestion
  );

  /********** */
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const query = cliente.nombre ? `?name=${cliente.nombre}` : ``;
      const countries = await apiService
        .httpGet(`${CLIENTES_URI}${query}`)
        .then((data) => {
          return data;
        });

      if (active) {
        setOptions(countries);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  /********** */
  const showErrorSlideMsg = useCallback((msg, state) => {
    const fnc = () => {
      setShowSlideMsg(false);
    };
    showSlideMsgFN(msg, state, fnc);
  }, []);

  useEffect(() => {
    async function loadQuestion() {
      const data = await apiService
        .httpGet(QUESTIONS_URI + "/" + questionId)
        .then((data) => {
          return data;
        });

      setCliente(data.cliente);
      setTitle(data.title);
      setPie(data.footer);
      setLogo(data.logo);

      setContent(data.content ? data.content : []);
      setTimeout(() => {}, 1000);
    }

    if (questionId) {
      loadQuestion();
    }
  }, [questionId]);

  const updateLogo = (id, content) => {
    if (!logo.content || content.content.files !== logo.content.files) {
      if (content.content.files.length > 0) {
        setLogo(content);
      }
    }
  };

  function handleSave() {
    const question = [
      {
        title: title,
        footer: pie,
        logo: logo,
        cliente: JSON.stringify(cliente),
        clienteData: clienteData,
        content: JSON.stringify(compoundQuestions),
      },
    ];
    onSave(question[0]);
  }

  function handleClose() {
    props.history.push("/questions");
  }

  function handleViewPdf() {
    window.open(`${BACKURL}/generate-fpdf/${questionId}`, "_blank");
  }

  async function saveQuestion(question) {
    if (!question.title || !question.cliente || !question.content) {
      showErrorSlideMsg("Complete todos los datos", "error");
      return false;
    }
    if (questionId) {
      question.id = questionId;
      apiService.httpPost(QUESTIONS_URI + "/save", question).then((data) => {
        if (data.errors) {
          showErrorSlideMsg(data.errors, "error");
        } else {
          showSuccessSlideMsg("Actualizado", "success");
          setTimeout(() => {
            //window.location = `/question/${data.id}`;
          }, 1000);
        }
      });
    } else {
      question.id = 0;
      apiService.httpPost(QUESTIONS_URI + "/save", question).then((data) => {
        if (data.errors) {
          showErrorSlideMsg(data.errors, "error");
        } else {
          showSuccessSlideMsg("Creado", "success");
          setTimeout(() => {
            window.location = `/question/${data.id}`;
          }, 1000);
        }
      });
    }
  }

  function showSuccessSlideMsg(msg, state) {
    setDisableSaveButton(true);
    const fnc = () => {
      setShowSlideMsg(false);
      ///////////props.history.push('/questions');
    };
    showSlideMsgFN(msg, state, fnc);
  }

  function showSlideMsgFN(msg, state, fnc) {
    setShowSlideMsg(true);
    setSlideMsg(msg);
    setSnackClass(state);
    setTimeout(fnc, 3500);
  }

  function handleCloseSlideMsg() {
    setShowSlideMsg(false);
    if (snackClass === "success") {
      props.history.push("/questions");
    }
  }

  function renderRedirect() {
    if (redirect) {
      return <Redirect to="/questions" />;
    }
  }

  return (
    <div className="simplelist-container">
      <Paper>
        <Grid container spacing={3}>
          <div className="simplelist-container">
            {renderRedirect()}
            <div className="simplelist-title">
              <h3>Presupuestos</h3>
            </div>
            <div className="question-body">
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-age-simple">Cliente:</InputLabel>
                <Autocomplete
                  id="asynchronous-demo"
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  getOptionSelected={(option, value) =>
                    option.nombre === value.nombre
                  }
                  getOptionLabel={(option) =>
                    option.nombre ? option.nombre : ""
                  }
                  options={options}
                  loading={loading}
                  value={cliente}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setCliente(newValue);
                      setClienteData(
                        `${newValue.codigoCuenta} ${newValue.nombre} ${newValue.empresa} ${newValue.email}`
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cliente"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                {}
                <div className="cliente-data">
                  {cliente ? cliente.codigoCuenta : ""}{" "}
                  {cliente ? cliente.email : ""}{" "}
                  {cliente ? cliente.empresa : ""}
                </div>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-age-simple">Título:</InputLabel>
                <TextField
                  id="title"
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-age-simple">Pié:</InputLabel>
                <TextField
                  id="pie"
                  type="text"
                  onChange={(e) => setPie(e.target.value)}
                  value={pie}
                />
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-age-simple">Logo:</InputLabel>
              </FormControl>
              <AttachmentQuestion
                setFromChild={updateLogo}
                id={"logo"}
                content={logo.content}
                setDisableSaveButton={setDisableSaveButton}
              />
              <CompoundQuestion
                setCompoundQuestions={setCompoundQuestions}
                content={content}
                setDisableSaveButton={setDisableSaveButton}
                onClose={onClose}
                handleSave={handleSave}
                handleViewPdf={handleViewPdf}
                questionId={questionId}
              />
              <div className="button-footer">
                <Button className="btn-cancel" onClick={onClose}>
                  Cancelar
                </Button>
                <Button className="btn-accept" onClick={handleSave}>
                  Guardar
                </Button>
                {questionId && (
                  <Button className="btn-accept" onClick={handleViewPdf}>
                    Ver PDF
                  </Button>
                )}
              </div>
            </div>
            <SliderAlert
              showSlideMsg={showSlideMsg}
              snackClass={snackClass}
              slideMsg={slideMsg}
              handleCloseSlideMsg={handleCloseSlideMsg}
            />
          </div>
        </Grid>
      </Paper>
    </div>
  );
}
