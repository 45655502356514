import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core";
import QuestionInfo from "./QuestionInfo";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles(() => ({
  modal: {
    marginTop: 100,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function QuestionInfoModal(props) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="max-width-dialog-title"
      TransitionComponent={Transition}
      className={classes.modal}
    >
      <DialogTitle id="max-width-dialog-title">Question</DialogTitle>
      <DialogContent>
        <QuestionInfo questionId={props.questionId} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} className="btn-cancel">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
