import React from 'react';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


export default function SliderAlert(props) {

    return (
        <Slide direction="left" in={props.showSlideMsg} mountOnEnter unmountOnExit>
            <Snackbar
                className={props.snackClass}
                open={true}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{props.slideMsg}</span>}
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={props.handleCloseSlideMsg}>
                        <CloseIcon/>
                    </IconButton>,
                ]}
            />
        </Slide>
    )
}