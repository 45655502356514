import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/styles/navbar.scss';
import logo from '../../assets/images/logogalindez.png';
import SignedInLinks from './SignedInLinks';

const Navbar = (props) => {
  const { userToken } = props;
  const links = userToken ? (
    <SignedInLinks signOut={props.signOut} handleLanguageChange={props.handleLanguageChange} language={props.language}/>
  ) : null;
  return (
    userToken && <header className="navbar">
      <nav className="navbar-navigation">
        <div />
        <div>
          <Link to="/" className="navbar-logo">
            <img src={logo} alt="Presupuestos"/>
          </Link>
        </div>
        <div className="spacer" />
        {links}
      </nav>
    </header>
  );
};

export default Navbar;
