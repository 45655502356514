import React, { useEffect } from "react";

import TextQuestionReadOnly from "./TextQuestionReadOnly";
import MultipleChoiceQuestionReadOnly from "./MultipleChoiceQuestionReadOnly";
import AttachmentQuestionReadOnly from "./AttachmentQuestionReadOnly";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "90%",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    float: "right",
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "90%",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  cardExam: {
    width: "100%",
  },
  cardExamItem: {
    display: "block",
    padding: "0",
    margin: "0",
  },
}));

export default function CompoundQuestionReadOnly(props) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);

  useEffect(() => {
    function loadSection() {
      if (props.content && props.content.length > 0) {
        let selectDummy = [];

        props.content.map((item, i) => {
          let newItem = null;
          const key = selected.length + i;
          switch (item.type) {
            case "text":
              newItem = {
                id: `item-${key}-result`,
                key: key,
                content: (
                  <TextQuestionReadOnly id={key} content={item.content} />
                ),
              };

              break;
            case "multiplechoice":
              newItem = {
                id: `item-${key}-result`,
                key: key,
                content: (
                  <MultipleChoiceQuestionReadOnly
                    id={key}
                    content={item.content}
                    showOptions={props.isCandidateExam ? false : true}
                  />
                ),
              };
              break;
            case "attachment":
              newItem = {
                id: `item-${key}-result`,
                key: key,
                content: (
                  <AttachmentQuestionReadOnly id={key} content={item.content} />
                ),
              };
              break;
            default:
              newItem = {
                id: `item-${key}-result`,
                key: key,
                content: (
                  <TextQuestionReadOnly id={key} content={item.content} />
                ),
              };
              break;
          }

          selectDummy.push(newItem);
          return true;
        });

        setSelected(selectDummy);
      }
    }

    loadSection();
  }, [props.content, props.isCandidateExam, selected.length]);

  return (
    <main className={classes.layout}>
      <React.Fragment>
        <div xs={12} md={6}>
          <List>
            {selected.map((item, index) => (
              <ListItem className={classes.cardExamItem} key={index}>
                {item.content}
              </ListItem>
            ))}
          </List>
        </div>
      </React.Fragment>
    </main>
  );
}
