import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";

class ArchiveDeleteDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleModalClick = (e) => {
    e.stopPropagation();
  };

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <div>
        <Dialog
          open={this.props.show}
          onClose={this.props.close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.header}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.close} color="primary">
              Cancel
            </Button>
            <Button onClick={this.props.action} color="primary" autoFocus>
              {this.props.btnAction}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ArchiveDeleteDialog;
