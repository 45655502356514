import React, { Component } from "react";
import { QUESTIONS_URI } from "../../Constants";
import apiService from "../../services/Api.service.js";
import "../../assets/styles/candidates.scss";
import SimpleList from "./../layout/SimpleList";

import SliderAlert from "../utils/SliderAlert";

class QuestionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        title: "Presupuestos",
        btnAddTitle: "Nuevo presupuesto",
        href: "/question",
        editUrl: "/question",
        method: "delete",
        switch: false,
        dialogTitle: "Borrar presupuesto",
        action: this.deleteQuestion,
        btnAction: "Borrar",
        duplicar: this.duplicar,
      },
      fieldQuestions: [
        {
          fieldName: "title",
          fieldType: "text",
          fieldId: "title",
          fieldValue: "",
          fieldLabel: "Título",
        },
        {
          fieldName: "Locked",
          fieldType: "hidden",
          fieldId: "locked",
          fieldValue: "",
          fieldLabel: "Bloqueado",
        },
        {
          fieldName: "deleted",
          fieldType: "hidden",
          fieldId: "deleted",
          fieldValue: "",
          fieldLabel: "Eliminado",
        },
        {
          fieldName: "tags",
          fieldType: "text",
          fieldId: "tags",
          fieldValue: "",
          fieldLabel: "Tags",
        },
        {
          fieldName: "id",
          fieldType: "hidden",
          fieldId: "id",
          fieldValue: "",
        },
      ],
      userToken: props.userToken,
      questions: [],
      isShowing: false,
      showSlideMsg: false,
      snackClass: "",
      msgSlideMsg: "",
    };
  }

  componentDidMount() {
    this.loadList();
  }

  duplicar = (id) => {
    apiService.httpGet(QUESTIONS_URI + "/duplicar/" + id).then((data) => {
      this.props.history.push("/question/" + data.id);
    });
  };

  loadList = () => {
    apiService.httpGet(QUESTIONS_URI).then((data) => {
      this.setState({
        questions: data,
      });
    });
  };

  deleteQuestion = async (id) => {
    apiService.httpDelete(QUESTIONS_URI + "/" + id).then((data) => {
      if (data.errors) {
        this.showSlideMsg(data.errors, "error");
      } else {
        this.loadList();
        this.showSlideMsg("Borrado", "success");
      }
    });
  };

  showSlideMsg = (msg, state) => {
    this.setState({ showSlideMsg: true });
    this.setState({ slideMsg: msg });
    this.setState({ snackClass: state });
    setTimeout(() => {
      this.setState({ showSlideMsg: false });
    }, 7000);
  };

  handleCloseSlideMsg = () => {
    this.setState({ showSlideMsg: false });
  };

  getQuestionsWithTags = () => {
    return this.state.questions.map((question) => {
      return {
        ...question,
        tags: [],
      };
    });
  };

  render() {
    let simpleList;

    if (this.state.questions) {
      const questions = this.getQuestionsWithTags();
      const filteredQuestions = [];
      for (let i = 0; i < questions.length; i++) {
        filteredQuestions.push([
          {
            fieldName: "id",
            fieldValue: questions[i]["id"],
            fieldType: "hidden",
            identifier: true,
          },
          {
            fieldName: "title",
            fieldValue: questions[i]["title"],
            fieldType: "text",
            fieldLabel: "Título",
          },
          {
            fieldName: "tags",
            fieldValue: questions[i]["tags"],
            fieldType: "text",
            fieldLabel: "Tags",
          },
          {
            fieldName: "locked",
            fieldValue: "" + questions[i]["locked"],
            fieldType: "hidden",
            fieldLabel: "Bloqueado",
          },
          {
            fieldName: "deleted",
            fieldValue: "" + questions[i]["deleted"],
            fieldType: "hidden",
            fieldLabel: "Borrado",
          },
        ]);
      }

      simpleList = (
        <SimpleList
          items={filteredQuestions}
          fields={this.state.fieldQuestions}
          options={this.state.options}
        />
      );
    }

    return (
      <div>
        {simpleList}
        <SliderAlert
          showSlideMsg={this.state.showSlideMsg}
          snackClass={this.state.snackClass}
          slideMsg={this.state.slideMsg}
          handleCloseSlideMsg={this.handleCloseSlideMsg}
        />
      </div>
    );
  }
}

export default QuestionList;
