import React, { useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import TextField from "@material-ui/core/TextField";

export default function TextQuestion(props) {
  const [description, setDescription] = React.useState("");

  useEffect(() => {
    if (props.content.description) {
      setDescription(props.content.description);
    }
  }, [props.content.description]);

  useEffect(() => {
    if (description !== "") {
      props.setFromChild(props.id, {
        id: props.id,
        type: props.subType,
        content: { description: description },
      });
    }
  }, [description]);

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor="outlined-age-simple">
        {props.icon} <span className="compound-title">{props.description}</span>
      </InputLabel>
      <TextField
        id="description"
        type="text"
        onChange={(e) => setDescription(e.target.value)}
        value={description}
        rows="10"
        multiline={true}
      />
    </FormControl>
  );
}
